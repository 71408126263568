import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as writtenNumber from 'written-number';

@Pipe({
  name: 'amountInLetters',
  standalone: true
})
export class AmountInLettersPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: number): string {
    if (!value && value !== 0) return '';
    
    const dinars = Math.floor(value);
    const centimes = Math.round((value - dinars) * 100);

    let amountText = writtenNumber(dinars, { 
      lang: this.translateService.currentLang 
    }) + ' dinars';

    if (centimes > 0) {
      amountText += ' et ' + writtenNumber(centimes, { 
        lang: this.translateService.currentLang 
      }) + ' centimes';
    }

    return amountText;
  }
} 