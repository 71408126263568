import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Folder } from 'app/shared/models/folder.model';
import { environment } from 'environments/environment';
import { DataStorageService } from './data.service';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FolderService {
    folderUrl = environment.ENDPOINTS.FOLDER_URL;
    disableFolderUrl = environment.ENDPOINTS.DISABLE_FOLDER_URL;
    companiesUrl = environment.ENDPOINTS.COMPANIES_URL;
    setLogoUrl = environment.ENDPOINTS.LOGO_SET_IMG_URL;
    companyPermissionsUrl = environment.ENDPOINTS.COMPANY_PERMISSION;
    notUrl = environment.ENDPOINTS.NOTIFICATIONS_URL;
    maskBN = [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/,
        /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/,
        /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/];
    constructor(private httpClient: HttpClient, private cacheStorageService: DataStorageService) { }

    /**
     * get a specific folder with an ID
     * @param idFolder id of the folder to get
     */
    getFolder(idFolder): Observable<any> {
        const req = this.folderUrl + `/${idFolder}`;
        // return this.httpClient.get<any>(req);
        if (!navigator.onLine) {
            return from(this.cacheStorageService.getItem('selectedFolder', 'localStorageShared', true)).pipe(
                catchError(() => this.httpClient.get<any>(req))
            );
        }
        return this.httpClient.get<any>(req).pipe(
            switchMap(response => {
                this.cacheStorageService.setItem(req, response);
                return of(response);
            })
        );
    }

    /**
     * edit a specific folder
     * @param folder folder to edit
     * @param remove whether to remove digital signature
     */
    updateFolder(folder: FormData, remove = false): Observable<any> {
        const req = this.folderUrl + `?removeDigitalSignature=${remove}`;
        return this.httpClient.put<any>(req, folder);
    }

    /**
     * create a folder
     * @param folder folder to create
     */
    createFolder(folder: FormData): Observable<any> {
        const req = this.folderUrl + `?applicationType=FAST`;
        return this.httpClient.post<any>(req, folder);
    }

    /**
     * get user folders
     */
    getUserFolder(): Observable<any> {
        const req = this.folderUrl;
        // return this.httpClient.get(req);
        if (!navigator.onLine) {
            return from(this.cacheStorageService.getItem(req)).pipe(
                catchError(() => this.httpClient.get<any>(req))
            );
        }
        return this.httpClient.get<any>(req).pipe(
            switchMap(response => {
                this.cacheStorageService.setItem(req, response);
                return of(response);
            })
        );
    }

    /**
     * update image for a folder
     * @param file image to set for the folder
     * @param id folder id
     */
    uploadImage(image: File, id): Observable<any> {
        const req = this.setLogoUrl + `/${id}`;
        const uploadData = new FormData();
        uploadData.append('image', image, image.name);
        return this.httpClient.post(req, uploadData);
    }

    /**
     * get all the folders
     */
    getAllFolders(accountIds?): Observable<any> {
        const req = accountIds ? this.folderUrl + `?accountIds=${accountIds}` : this.folderUrl + `?size=2000`;
        // return this.httpClient.get<any>(req);
        if (!navigator.onLine) {
            return from(this.cacheStorageService.getItem(req)).pipe(
                catchError(() => this.httpClient.get<any>(req))
            );
        }
        return this.httpClient.get<any>(req).pipe(
            switchMap(response => {
                this.cacheStorageService.setItem(req, response);
                return of(response);
            })
        );
    }

    /**
     * Delete Folder
     * @param folderId folder id
     */
    deleteFolder(folderId: any): Observable<any> {
        const req = this.disableFolderUrl + `/${folderId}?applicationType=FAST`;
        return this.httpClient.delete<any>(req);
    }

    getCompanyPermissions(companyId): Observable<any> {
        const req = this.companyPermissionsUrl + `/${companyId}`;
        // return this.httpClient.get<any>(req);
        if (!navigator.onLine) {
            return from(this.cacheStorageService.getItem(req)).pipe(
                catchError(() => this.httpClient.get<any>(req))
            );
        }
        return this.httpClient.get<any>(req).pipe(
            switchMap(response => {
                this.cacheStorageService.setItem(req, response);
                return of(response);
            })
        );
    }

    getNotifications(companyId: number, size: number, page: number) {
        const req = this.notUrl + `/${companyId}/notifications?sort=creationDate,desc&size=${size}&page=${page}`;
        // return this.httpClient.get<any>(req, { observe: 'response' });
        if (!navigator.onLine) {
            return from(this.cacheStorageService.getItem(req)).pipe(
                catchError(() => this.httpClient.get<any>(req, { observe: 'response' }))
            );
        }
        return this.httpClient.get<any>(req, { observe: 'response' }).pipe(
            switchMap(response => {
                this.cacheStorageService.setItem(req, response);
                return of(response);
            })
        );
    }

    updateNotificationsReceivedToTrue(companyId, notifId?) {
        const req1 = this.notUrl + `/${companyId}/notifications`;
        const req2 = notifId ? `/${notifId}` : '';
        const mainReq = req1 + req2;
        return this.httpClient.post(mainReq, {});
    }

    getEmptyFolders(accountIds?): Observable<any> {
        const req = accountIds ? this.folderUrl + `?accountIds=${accountIds}` : this.folderUrl + `?size=1`;
        // return this.httpClient.get<any>(req);
        if (!navigator.onLine) {
            return from(this.cacheStorageService.getItem(req)).pipe(
                catchError(() => this.httpClient.get<any>(req))
            );
        }
        return this.httpClient.get<any>(req).pipe(
            switchMap(response => {
                this.cacheStorageService.setItem(req, response);
                return of(response);
            })
        );
    }

    getAllPannes({ companyId, sort, dir, page, size, search }: { companyId: number, sort: string, dir: string, page: number, size: number, search?: string }) {
        const searchParam = search ? `&search=${search}` : '';
        const req = `${this.companiesUrl}/${companyId}/malfunctions` + `?page=${page}` + `&size=${size}&sort=${sort},${dir}` + searchParam;

        return this.httpClient.get(req, { observe: 'response' });
    }

    deletePanne({ companyId, panneId }: { companyId: number, panneId: number }) {
        const req = `${this.companiesUrl}/${companyId}/malfunctions/${panneId}`;

        return this.httpClient.delete(req, { observe: 'response' });
    }

    enableDisablePanne({ companyId, panneId, isDisable = false }: { companyId: number, panneId: number, isDisable: boolean }) {
        const req = `${this.companiesUrl}/${companyId}/malfunctions/${panneId}/${isDisable ? 'disable' : 'enable'}`;

        return this.httpClient.post(req, {});
    }

    saveAllPannes({ companyId, pannesToSend }: { companyId: number, pannesToSend: any[] }) {
        const req = `${this.companiesUrl}/${companyId}/malfunctions`;

        return this.httpClient.post(req, pannesToSend);
    }

}
