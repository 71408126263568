<div class="model5-container">
  <!-- Header with company details -->
  <div class="model5-header">
    <div class="company-info">
      <div class="company-logo" *ngIf="imageUrl">
        <img [src]="imageUrl" alt="Company Logo" />
      </div>
      <div class="company-details">
        <div class="company-name">{{folder?.name}}</div>
        <div *ngIf="folder?.street" >{{folder?.street}}</div>
        <div *ngIf="folder?.phoneNumber" >{{'viewDocs.phone' |translate}}: {{folder?.phoneNumber}}</div>
        <div *ngIf="folder?.email" >{{'viewDocs.email' |translate}}{{folder?.email}}</div>
        <div *ngIf="folder?.webSite" >{{'viewDocs.webSite' |translate}}: {{folder?.webSite}}</div>
      </div>
    </div>
  </div>

  <div class="company-ids">
    <div class="id-row">
      <span>{{'viewDocs.commercialRegisterNumber' |translate}}: {{folder?.commercialRegisterNumber}}</span>
      <span>{{'viewDocs.nis' |translate}}: {{folder?.nis}}</span>
      <span>{{'viewDocs.nif' |translate}}: {{folder?.nif}}</span>
      <span>{{'viewDocs.taxItemNum' |translate}}: {{folder?.taxationArticle}}</span>
      <span>{{'viewDocs.rib' |translate}}: {{folder?.bankAccountNumber}}</span>
    </div>
  </div>

  <!-- Document Title -->
  <div class="doc-title">
    <h2>{{docs.name}}</h2>
  </div>

  <div class="info-grid">
    <!-- Left side info -->
    <div class="left-info">
      <!-- Emitter Name -->
      <div class="info-line" *ngIf="docTitle !== 'return_voucher' && docTitle !== 'asset' && docType === 'STOCK'">
        <span class="bold-span">{{'viewDocs.emitterName'|translate}}</span>
        
        <!-- Pour transfer_voucher, exitVoucher et damage -->
        <ng-container *ngIf="['transfer_voucher', 'exitVoucher', 'damage'].includes(docTitle)">
          {{docs.emitterDepositName}}
        </ng-container>
        
        <!-- Pour entryVoucher -->
        <ng-container *ngIf="docTitle === 'entryVoucher'">
          {{(docs.clientType)==='PARTICULAR' && docs.clientCivility 
            ? ('viewDocs.clientCivility.' + docs.clientCivility | translate) + '. ' : ''}}
          {{(docs.emitterType)==='CLIENT' 
            ? docs.clientFullName
            : (docs.emitterType)==='DEPOSIT'
              ? docs.emitterDepositName
              : docs.providerName}}
        </ng-container>
      </div>

      <!-- Emitter Address -->
      <div class="info-line" *ngIf="docTitle !== 'return_voucher' && docTitle !== 'asset' && docType === 'STOCK'">
        <span class="bold-span">{{'viewDocs.address'|translate}}</span>
        <ng-container [ngSwitch]="docTitle">
          <!-- Pour transfer_voucher et exitVoucher -->
          <ng-container *ngSwitchCase="'transfer_voucher'">
            {{docs.emitterDepositAddress | fullAddress: docs.emitterDepositCommuneId: docs.emitterDepositWilayaId: commune : wilaya}}
          </ng-container>
          
          <ng-container *ngSwitchCase="'exitVoucher'">
            {{docs.emitterDepositFullAddress | fullAddress: docs.emitterDepositCommuneId: docs.emitterDepositWilayaId: commune : wilaya}}
          </ng-container>

          <!-- Pour damage -->
          <ng-container *ngSwitchCase="'damage'">
            {{docs.emitterDepositFullAddress | fullAddress: docs.emitterDepositCommuneId: docs.emitterDepositWilayaId: commune : wilaya}}
          </ng-container>

          <!-- Pour entryVoucher -->
          <ng-container *ngSwitchCase="'entryVoucher'">
            {{docs.emitterType ==='DEPOSIT' 
              ? (docs.emitterDepositFullAddress | fullAddress: docs.emitterDepositCommuneId: docs.emitterDepositWilayaId: commune : wilaya)
              : docs.emitterType ==='CLIENT' 
                ? (docs.clientFullAddress | fullAddress: docs.clientCommuneId: docs.clientWilayaId: commune : wilaya)
                : (docs.providerFullAddress | fullAddress: docs.providerCommuneId: docs.providerWilayaId: commune : wilaya)}}
          </ng-container>
        </ng-container>
      </div>

      <!-- Document Info -->
      <div class="info-line" *ngIf="docs.companyAddress && docType === 'STOCK'">
        <span >{{docs.companyAddress}}</span>
      </div>
       <!-- created -->
       <div class="info-line" *ngIf="docs.created">
        <span>{{'viewDocs.createAt'|translate}} {{docs.created}}</span>
      </div>

      <!-- Purchase Orders -->
      <div class="info-line" *ngIf="docs?.purchaseOrders?.length > 0">
        {{'viewDocs.type.purchaseOrder'|translate}}: 
        <span *ngFor="let order of docs?.purchaseOrders; let last = last">
          {{order.name}}{{last ? '' : ', '}}
        </span>
      </div>

      <!-- Date -->
      <div class="info-line">
        {{'viewDocs.date' |translate}}: {{docs.date}}
      </div>

      <!-- Payment Term -->
      <div class="info-line" *ngIf="docs.paymentTermId">
        {{'viewDocs.paymentTerm' |translate}}: {{ getPaymentTerm(docs.paymentTermId) }}
      </div>

      <!-- Due Date - Only for quotes -->
      <div class="info-line" *ngIf="docTitle === 'quote'">
        {{'viewDocs.dueDate' |translate}}: {{docs.dueDate | date:'dd-MM-yyyy' }}
      </div>

      <div class="info-line" *ngIf="docs.clientCode">
        <span class="bold-span">{{'viewDocs.clientCode'|translate}}:</span>
        {{docs.clientCode}}
      </div>
  
    </div>

    <!-- Right side client box -->
    <div class="client-box" *ngIf="docTitle !== 'damage'">
      <div class="client-label">
        <!-- Conditional label based on document type -->
        <ng-container [ngSwitch]="true">
          <!-- Stock documents -->
          <ng-container *ngSwitchCase="docType === 'STOCK'">
            <span *ngIf="docTitle === 'entryVoucher' || docTitle === 'transfer_voucher'">
              {{'viewDocs.deposit' | translate}}:
            </span>
            <span *ngIf="docTitle === 'exitVoucher'">
              {{(docs.targetType === 'DEPOSIT' ? 'viewDocs.deposit' : 
                 docs.targetType === 'PROVIDER' ? 'viewDocs.provider' : 'viewDocs.client') | translate}}:
            </span>
          </ng-container>
          <!-- Default case for other document types -->
          <ng-container *ngSwitchDefault>
            {{'viewDocs.clientLabel' |translate}}:
          </ng-container>
        </ng-container>
        <!-- Client Name with conditional display -->
        <div class="client-name">
          <ng-container [ngSwitch]="true">
            <!-- Stock documents -->
            <ng-container *ngSwitchCase="docType === 'STOCK'">
              <ng-container *ngIf="docTitle === 'entryVoucher' || docTitle === 'transfer_voucher'">
                {{docs.targetDepositName}}
              </ng-container>
              <ng-container *ngIf="docTitle === 'exitVoucher'">
                <ng-container *ngIf="docs.targetType === 'PROVIDER'">
                  {{docs.providerName}}
                </ng-container>
                <ng-container *ngIf="docs.targetType !== 'PROVIDER'">
                  {{docs.clientType === 'PARTICULAR' && docs.clientCivility ? 
                    ('viewDocs.clientCivility.' + docs.clientCivility | translate) + '. ' : ''}}
                  {{docs.targetType === 'DEPOSIT' ? docs.targetDepositName : docs.clientFullName}}
                </ng-container>
              </ng-container>
            </ng-container>
            <!-- Default case for other document types -->
            <ng-container *ngSwitchDefault>
              {{getLegalFormName(docs.clientLegalFormId)!=='PERSONNE PHYSIQUE' &&
                getLegalFormName(docs.clientLegalFormId)!=='SUCCURSALE' &&
                getLegalFormName(docs.clientLegalFormId)!=='AGENCE' &&
                getLegalFormName(docs.clientLegalFormId)!=='GROUPEMENT'
                ? getLegalFormName(docs.clientLegalFormId) : ' '}}
              {{docs.clientType === 'PARTICULAR' && docs.clientCivility ? 
                ('viewDocs.clientCivility.' + docs.clientCivility | translate) + '. ' : ''}}
              {{docs.clientName ? docs.clientName : docs.clientFullName ? docs.clientFullName : '/'}}
            </ng-container>
          </ng-container>
        </div>
        
      </div>

      <div class="client-content">
        <!-- Common Info -->
        <div class="info-line" *ngIf="docs.clientFullAddress || docs.clientAddress || docs.targetDepositFullAddress || docs.providerFullAddress">
          <span class="bold-span">{{'viewDocs.address'|translate}}</span>
          <ng-container [ngSwitch]="true">
            <!-- Cas pour les documents STOCK -->
            <ng-container *ngSwitchCase="docType === 'STOCK' && (docTitle === 'entryVoucher' || docTitle === 'transfer_voucher')">
              {{docs.targetDepositFullAddress}}
            </ng-container>
            
            <!-- Cas spécial pour exitVoucher -->
            <ng-container *ngSwitchCase="docType === 'STOCK' && docTitle === 'exitVoucher'">
              <ng-container [ngSwitch]="docs.targetType">
                <ng-container *ngSwitchCase="'PROVIDER'">
                  {{docs.providerFullAddress | fullAddress: docs.providerCommuneId: docs.providerWilayaId: commune : wilaya}}
                </ng-container>
                <ng-container *ngSwitchCase="'DEPOSIT'">
                  {{docs.targetDepositFullAddress}}
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{docs.clientFullAddress | fullAddress: docs.clientCommuneId: docs.clientWilayaId: commune : wilaya}}
                </ng-container>
              </ng-container>
            </ng-container>

            <!-- Cas par défaut pour les autres documents -->
            <ng-container *ngSwitchDefault>
              {{ docs.clientFullAddress ? docs.clientFullAddress : (docs.clientAddress | fullAddress: docs.clientCommuneId: docs.clientWilayaId: commune : wilaya) }}
            </ng-container>
          </ng-container>
        </div>

        <!-- Conditional Client Info -->
        <ng-container *ngIf="docs.clientType !== 'PARTICULAR'; else particularClient">
          <!-- Professional Client Info -->
          <div class="info-line" *ngIf="docs.clientNis">
            <span class="bold-span">{{'viewDocs.nis'|translate}}:</span>
            {{docs.clientNis}}
          </div>
          <div class="info-line" *ngIf="docs.clientNif">
            <span class="bold-span">{{'viewDocs.nif'|translate}}:</span>
            {{docs.clientNif}}
          </div>
          <div class="info-line" *ngIf="docs.clientBankAccountNumber">
            <span class="bold-span">{{'viewDocs.rib'|translate}}:</span>
            {{docs.clientBankAccountNumber}}
          </div>
          <div class="info-line" *ngIf="docs.clientCommercialRegisterNumber">
            <span class="bold-span">{{'viewDocs.commercialRegister'|translate}}:</span>
            {{docs.clientCommercialRegisterNumber}}
          </div>
          <div class="info-line" *ngIf="docs.clientTaxationArticle">
            <span class="bold-span">{{'viewDocs.taxItemNumber'|translate}}:</span>
            {{docs.clientTaxationArticle}}
          </div>
        </ng-container>

        <!-- Template for PARTICULAR clients -->
        <ng-template #particularClient>
          <div class="info-line" *ngIf="docs.clientPhoneNumber">
            <span class="bold-span">{{'viewDocs.phone'|translate}}:</span>
            {{docs.clientPhoneNumber}}
          </div>
          <div class="info-line" *ngIf="docs.clientEmail">
            <span class="bold-span">{{'viewDocs.email'|translate}}:</span>
            {{docs.clientEmail}}
          </div>
        </ng-template>

        <!-- Additional Professional Cards -->
        <div class="info-line" *ngIf="docs.clientFarmersCardNumber">
          <span class="bold-span">{{'viewDocs.farmer_card'|translate}}:</span>
          {{docs.clientFarmersCardNumber}}
        </div>
        <div class="info-line" *ngIf="docs.clientApprovalNumber">
          <span class="bold-span">{{'viewDocs.approval_number'|translate}}:</span>
          {{docs.clientApprovalNumber}}
        </div>
        <div class="info-line" *ngIf="docs.clientCraftsmanCard">
          <span class="bold-span">{{'viewDocs.crafts_card'|translate}}:</span>
          {{docs.clientCraftsmanCard}}
        </div>
      </div>
    </div>
  </div>
  <span class="edited-by">
    {{(docTitle==='invoices'?('viewDocs.editedBy'|translate):('viewDocs.edited'|translate)) +
    (docs.updatedBy?docs.updatedBy:docs.createdBy)}}
  </span>
  <!-- Product Table -->
  <div class="model5-products-table">
    <!-- Mode groupé pour return-voucher et asset -->
    <ng-container *ngIf="docTitle === 'return_voucher' || docTitle === 'asset'">
        <div *ngFor="let document of docs.products" class="document-group">
            <div class="document-header">{{document.name}}</div>
            
            <table class="grouped-products-table">
                <thead>
                    <tr>
                        <th>{{'viewDocs.ref'|translate}}</th>
                        <th>{{'viewDocs.description'|translate}}</th>
                        <th>{{'viewDocs.htPrice'|translate}}</th>
                        <th>{{'viewDocs.quantity'|translate}}</th>
                        <th>{{'viewDocs.discount'|translate}}</th>
                        <th>{{'viewDocs.ht'|translate}}</th>
                        <th >{{'viewDocs.ttc'|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let product of document.product">
                        <td>{{product.productInLinkedDocument?.productReference || '/'}}</td>
                        <td>{{product.name}}</td>
                        <td class="text-right">{{product.unitPrice | number:'1.2-2'}}</td>
                        <td class="text-right">{{product.quantities}}</td>
                        <td class="text-right">{{product.discountValue | number:'1.2-2'}}</td>
                        <td class="text-right">{{product.preTaxPriceAfterDiscount | number:'1.2-2'}}</td>
                        <td class="text-right">{{product.totalPrice | number:'1.2-2'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <!-- Mode normal pour les autres types -->
    <ng-container *ngIf="(docTitle !== 'return_voucher' || (docTitle === 'return_voucher' && !docs.withDeliveryNotes))">
        <table>
            <thead>
                <tr>
                    <th>{{'viewDocs.description' | translate}}</th>
                    <th>{{'viewDocs.quantity' | translate}}</th>
                    <th *ngIf="docType !== 'STOCK' || docTitle === 'damage'">{{'viewDocs.puht' | translate}}</th>
                    <th *ngIf="docType !== 'STOCK' || docTitle === 'damage'">{{'viewDocs.amount' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let product of docs.productDTOS">
                    <td>{{product.name}}</td>
                    <td class="text-right">{{product.quantity}}</td>
                    <td class="text-right" *ngIf="docType !== 'STOCK' || docTitle === 'damage'">{{product.unitPrice | number:'1.2-2'}} DA</td>
                    <td class="text-right" *ngIf="docType !== 'STOCK' || docTitle === 'damage'">{{product.preTaxPrice | number:'1.2-2'}} DA</td>
                </tr>
            </tbody>
        </table>
    </ng-container>

    <!-- Ajout de la section des ristournes -->
    <div *ngIf="docs && docTitle === 'asset' && docs.rebateDTOS?.length > 0">
      <table class="model5-table">
        <thead>
          <tr>
            <th>{{'viewDocs.ristourne'|translate}}</th>
            <th>{{'viewDocs.amount'|translate}}</th>
            <th>{{'viewDocs.tva'|translate}}</th>
            <th>{{'viewDocs.ttc'|translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let rebate of docs.rebateDTOS">
            <td>{{rebate.name}}</td>
            <td class="text-right">{{rebate.amount | number:'1.2-2'}}</td>
            <td class="text-right">{{getRebateTva(rebate) | number:'1.2-2'}}</td>
            <td class="text-right">{{rebate.totalAmount | number:'1.2-2'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Totals and Amount in Letters -->
  <div class="totals-and-letters">
    <!-- Amount in Letters à gauche -->
    <div class="amount-in-letters" [ngClass]="{footer_stock : docType === 'STOCK'}">
      <div class="letter-sections-title" *ngIf="docType !== 'STOCK' || docTitle === 'damage'">
        {{ 'viewDocs.arreteDocument.' + docTitle | translate }} : <span class="details"> {{ docs.totalPrice |
          amountInLetters }}</span>
      </div>
      <!-- Note de bas de page -->
      <div class="footer" *ngIf="docs.footNote">
        <p>{{docs.footNote}}</p>
      </div>
    </div>

    <!-- Totals à droite -->
    
      <div class="totals" *ngIf="docType !== 'STOCK' || docTitle === 'damage'">
        <!-- Prix HT avant remise -->
        <div class="total-row">
          <span>{{'viewDocs.htPrice' | translate}}</span>
          <span>{{docs.preTaxPriceBeforeDiscount | number:'1.2-2'}} DA</span>
        </div>

        <!-- Remise -->
        <div class="total-row" *ngIf="discountAmount > 0">
          <span>{{'viewDocs.discount' | translate}} ({{docs.discount | number:'1.1-1'}}%)</span>
          <span>-{{discountAmount | number:'1.2-2'}} DA</span>
        </div>

        <!-- Total HT après remise -->
        <div class="total-row">
          <span>{{'viewDocs.htTotal' | translate}}</span>
          <span>{{docs.preTaxPriceAfterDiscount | number:'1.2-2'}} DA</span>
        </div>

        <!-- TVA par taux -->
        <ng-container *ngFor="let tva of valueAddedTaxesWithRates">
          <div class="total-row" *ngIf="tva.cost > 0">
            <span>{{'viewDocs.tva' | translate}} {{tva.rate| percent:'1.1-1':'fr'}} </span>
            <span>{{tva.cost | number:'1.2-2'}} DA</span>
          </div>
        </ng-container>

        <!-- Total TVA -->
        <div class="total-row">
          <span>{{'viewDocs.tvaTotal' | translate}}</span>
          <span>{{docs.totalTaxCostAfterDiscount | number:'1.2-2'}} DA</span>
        </div>

        <!-- Stamp Fees -->
        <div class="total-row" *ngIf="docs.paymentTermId === 1">
          <span>
            {{'viewDocs.stampFees' | translate}}
            ({{stampFees.rateDisplay}})
          </span>
          <span>{{stampFees.value | number:'1.2-2'}} DA</span>
        </div>

        <!-- Total TTC -->
        <div class="total-row total">
          <span>{{'viewDocs.ttcTotal' | translate}}</span>
          <span>{{docs.totalPrice | number:'1.2-2'}} DA</span>
        </div>
      </div>
    
  </div>

 

  <!-- Signatures -->
  <div class="signatures">
    <div class="signature-block">
      <div class="signature-label">{{'signature'|translate}}</div>
      <img *ngIf="digitalSignatureUrl" [src]="sanitizer.bypassSecurityTrustUrl(digitalSignatureUrl)" 
           alt="Digital Signature" class="digital-signature">
      <div class="signature-space" *ngIf="!digitalSignatureUrl"></div>
    </div>
    
    <div class="signature-block">
      <div class="signature-label">{{'viewDocs.clientSignature'|translate}}</div>
      <img *ngIf="clientSignatureUrl" [src]="sanitizer.bypassSecurityTrustUrl(clientSignatureUrl)" 
           alt="Client Signature" class="digital-signature">
      <div class="signature-space" *ngIf="!clientSignatureUrl"></div>
    </div>
  </div>
</div> 