import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullAddress',
  pure: true,
  standalone: true,
})
export class FullAddressPipe implements PipeTransform {
  transform(
    street: string,
    communeCode: string,
    wilayaCode: string,
    communes: any[],
    wilayas: any[]
  ): string {
    const parts = [];
    if (street) {
      parts.push(street);
    }
    if (communeCode) {
      const foundCommune = communes.find(c => c.code === communeCode);
      if (foundCommune) {
        parts.push(foundCommune.name);
      }
    }
    if (wilayaCode) {
      const foundWilaya = wilayas.find(w => w.code === wilayaCode);
      if (foundWilaya) {
        parts.push(foundWilaya.name);
      }
    }
    return parts.join(', ') || '/';
  }
}