import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { StorageUtils } from "app/shared/storage-utils";
import { FullAddressPipe } from '@fuse/pipes/FullAddress.pipe';
import * as writtenNumber from 'written-number';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { StampFees } from 'app/shared/models/stampfees.model';
import { ProductDTO } from 'app/shared/models/product-dto.model';
import { AmountInLettersPipe } from 'app/shared/pipes/amount-in-letters.pipe';

@Component({
  selector: 'app-model5-doc',
  templateUrl: './model5-doc.component.html',
  styleUrls: ['./model5-doc.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    TranslateModule, 
    FullAddressPipe, 
    DatePipe,
    AmountInLettersPipe
  ],
  providers: [AmountInLettersPipe]
})
export class Model5DocComponent implements OnInit, OnChanges {
  @Input() docs;
  @Input() docTitle: string;
  @Input() docType: string;
  @Input() imageUrl: string;
  @Input() folder: any;
  @Input() balance: number;
  @Input() digitalSignatureUrl: string;
  @Input() clientSignatureUrl: string;
  @Input() imageUrlClient: string;
  legalForm = [];
  paymentTermList = [];
  wilaya = [];
  commune = [];
  computedClientAddress: string;
  amountInLetters: string;
  documentType: string;
  discountAmount = 0;
  stampFees: StampFees;

  valueAddedTaxesWithRates: Array<{
    valueAddedTaxId: number;
    cost: number;
    rate: number;
  }> = [];

  constructor(
    public sanitizer: DomSanitizer,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    
    this.paymentTermList = StorageUtils.getPayementTerm();
    this.getLocalStorageItems();

    if (this.docs?.valueAddedTaxCosts) {
      this.valueAddedTaxesWithRates = this.docs.valueAddedTaxCosts
        .filter(tva => {
          const rate = this.calculateTVARate(tva.valueAddedTaxId);
          return tva.cost > 0 && rate > 0;
        })
        .map(tva => ({
          ...tva,
          rate: this.calculateTVARate(tva.valueAddedTaxId)
        }));
    }

    // Calcul du montant de la remise
    if (this.docs) {
      this.calculateDiscount();
    }

    // Initialiser les frais de timbre
    this.calculateStampFees();
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes.docs) {
      if (this.docs?.valueAddedTaxCosts) {
        this.valueAddedTaxesWithRates = this.docs.valueAddedTaxCosts
          .filter(tva => {
            const rate = this.calculateTVARate(tva.valueAddedTaxId);
            return tva.cost > 0 && rate > 0;
          })
          .map(tva => ({
            ...tva,
            rate: this.calculateTVARate(tva.valueAddedTaxId)
          }));
      }
      if (this.docs) {
        this.calculateDiscount();
      }
      this.calculateStampFees();
    }
  }

  getLocalStorageItems() {
    this.wilaya = StorageUtils.getWilayas();
    this.commune = StorageUtils.getCommunes();
    this.legalForm = StorageUtils.getLegalForms();
  }

  getProductReference(product: ProductDTO): string {
    return product.productReference || '/';
  }

  isSubsidizedProduct(product: ProductDTO): boolean {
    return product.dsa === true;
  }

  getPaymentTerm(paymentTermId: number): string {
    if (!paymentTermId) return '';
  
    if (paymentTermId === 15) {
      return this.docs.customPaymentTerm || '';
    }
  
    const paymentTerm = this.paymentTermList.find(({ id }) => id === paymentTermId);
    return paymentTerm?.name || '';
  }


  getLegalFormName(legalFormId) {
    let name = "";
    if (legalFormId) {
      const legalFormName = this.legalForm.find(
        (form) => form.id === legalFormId
      ).name;
      if (legalFormName.indexOf("-") !== -1) {
        name = legalFormName.substring(0, legalFormName.indexOf("-"));
      } else {
        name = legalFormName.substring(legalFormName.indexOf("-") + 1);
      }
    }
    return name;
  }

  getFullAddress(street: string, communeCode: number, wilayaCode: number): string {
    const parts = [];
    if (street) {
      parts.push(street);
    }
    if (communeCode) {
      const found = this.commune.find(c => c.code === communeCode);
      if (found) {
        parts.push(found.name);
      }
    }
    if (wilayaCode) {
      const found = this.wilaya.find(w => w.code === wilayaCode);
      if (found) {
        parts.push(found.name);
      }
    }
    return parts.join(', ') || '/';
  }

  private calculateTVARate(valueAddedTaxId: number): number {
    switch(valueAddedTaxId) {
      case 1: return 0.0;
      case 2: return 0.09;
      case 3: return 0.19;
      default: return 0;
    }
  }

  private calculateDiscount(): void {
    if (this.docs.discountType === 'PERCENTAGE' && this.docs.discount) {
      // Si c'est un pourcentage, on calcule le montant
      this.discountAmount = this.docs.preTaxPriceBeforeDiscount - this.docs.preTaxPriceAfterDiscount;
    } else {
      // Si c'est un montant direct
      this.discountAmount = this.docs.discount || 0;
    }
  }

  private calculateStampFees() {
    if (!this.docs?.stampFees || this.docs?.paymentTermId !== 1) {
      this.stampFees = {
        rate: 0,
        rateDisplay: '0 DA',
        value: 0,
        isEnabled: false
      };
      return;
    }

    const totalTTC = this.docs.preTaxPriceAfterDiscount + this.docs.totalTaxCostAfterDiscount;
    let rate: number;

    switch (true) {
      case totalTTC <= 300:
        rate = -1;
        break;
      case totalTTC <= 30000:
        rate = 1;
        break;
      case totalTTC <= 100000:
        rate = 1.5;
        break;
      default:
        rate = 2;
    }

    this.stampFees = {
      rate,
      rateDisplay: rate === -1 ? '5 DA' : (rate / 100).toLocaleString('fr-FR', { style: 'percent', minimumFractionDigits: 1 }),
      value: this.docs.stampFees,
      isEnabled: true
    };
  }

  getRebateTva(rebate: any): number {
    return rebate.totalAmount - rebate.amount;
  }
}
